<template>
  <page-view :title="title" left-arrow :nav-border="false" custom-class="dfzwPage">
    <template #nav-right>
      <yhbh-select v-model="yhbh" @confirm="yhbhChangeHandle" style="margin-right: -16px;" />
    </template>

    <loading-layout ref="loadingLayoutOuter" error-text="绑定户号信息查询失败" empty-text="未绑定用户编号">
      <template #empty>
        <yhbh-empty fromPage="yhzd-query" />
      </template>

      <div class="top">
        <!-- 个人 -->
        <van-cell-group v-if="yhJbxx.nsbz == '0'" :border="false">
          <van-cell title="纳税标志：" :value="yhJbxx.nsbz | zfdmFilter(nsbzZfdmList)">
            <template #right-icon>
              统一结算号：{{yhJbxx.tyjsh && yhJbxx.tyjsh!=0?yhJbxx.tyjsh:'无'}}
            </template>
          </van-cell>
          <van-cell title="户号：" :value="yhJbxx.yhbh" />
          <van-cell title="户名：" :value="yhJbxx.yhmc" />
          <van-cell title="发票抬头：" :value="yhJbxx.yhmc" />
          <van-cell title="证件编号：" :value="yhJbxx.zjbh" />
          <van-cell title="电子邮箱：" :value="yhJbxx.email" />
        </van-cell-group>
        <!-- 企业 -->
        <van-cell-group v-else :border="false">
          <van-cell title="纳税标志：" :value="yhJbxx.nsbz | zfdmFilter(nsbzZfdmList)">
            <template #right-icon>
              统一结算号：{{yhJbxx.tyjsh && yhJbxx.tyjsh!=0?yhJbxx.tyjsh:'无'}}
            </template>
          </van-cell>
          <van-cell title="户号：" :value="yhJbxx.yhbh" />
          <van-cell title="户名：" :value="yhJbxx.yhmc" />
          <van-cell title="发票抬头：" :value="yhNsxx.nsrmc" />
          <van-cell title="纳税识别号：" :value="yhNsxx.nsrsbh" />
          <van-cell title="联系电话：" :value="yhNsxx.nsrdh" />
        </van-cell-group>
        <div style="margin-top: -30px;position: relative;display: flex;justify-content: flex-end;">
          <span @click="changeKpsmVisible" style="margin: 5px 5px 0 0;color: white;font-size: 14px;text-decoration:underline;">开票说明</span>
<!--          <van-button type="default" size="small" @click="editDzfptt">维护发票抬头</van-button>-->
        </div>
      </div>

      <van-tabs @click="onClick" v-model="activeTabIndex" sticky offset-top="44" title-active-color="#00b1ff" color="#00b1ff">
          <van-list class="dzfp-list" v-model="listLoading" :finished="listFinished" finished-text="没有更多了" :error.sync="listLoadError" error-text="加载失败，点击重试"
            @load="fetchDzfpList">
        <van-tab v-for="(item,index) in list" :title="item.nf+ ' 年'" :key="index">
            <div :class="['dzfp-item',child.pdfurl || child.dybz=='1'?'invoice-detail-view':'']" v-for="(child,idx) in recordList" :key="idx">
              <div class="dzfp-item--header">
                <van-tag type="primary" class="zwyf mt-15">{{child.zwyf}}</van-tag>
<!--                <van-tag type="warning" v-if="yhJbxx.tyjsh && yhJbxx.tyjsh != '0' ? true: false">合打</van-tag>-->
                <van-tag type="warning" v-if="child.hbbh && child.hbbh != '0' ? true: false">合打</van-tag>
              </div>
              <van-cell-group class="dzfp-item--content">
<!--                <van-cell :border="false" title="统一结算号：">{{yhJbxx.tyjsh && yhJbxx.tyjsh!=0?yhJbxx.tyjsh:'无'}}</van-cell>-->
                <van-cell :border="false" title="票据类型：" v-if="child.pjdylxmc">{{child.pjdylxmc}}</van-cell>
                <van-cell :border="false" title="统一结算号：">{{child.hbbh && child.hbbh!=0?child.hbbh:'无'}}</van-cell>
                <van-cell :border="false" title="总电量：">{{child.dl}}</van-cell>
                <van-cell :border="false" title="总电费：">{{child.ysdf | currency}}</van-cell>
                <van-cell :border="false" title="实收违约金：">{{child.sswyj | currency}}</van-cell>
                <van-cell :border="false" title="已开票金额：">{{child.ykdf | currency}}</van-cell>
                <van-cell :border="false" title="未开票金额：">{{child.wkdf | currency}}</van-cell>
                <van-cell :border="false" title="作废审核状态：" v-if="child.zfsqdqzt">
                  <van-tag type="warning" v-if="child.zfsqdqzt=='0'">审核中</van-tag>
                  <van-tag type="success" v-else-if="child.zfsqdqzt=='1'">审核通过</van-tag>
                  <van-tag type="danger" v-else-if="child.zfsqdqzt=='2'">审核不通过</van-tag>
                </van-cell>
                <van-cell :border="false" title="作废审核反馈意见：" v-if="['1','2'].includes(child.zfsqdqzt)">{{child.zfsqspyj}}</van-cell>
              </van-cell-group>
              <div class="dzfp-item--actions">
                <van-button v-if="child.pdfurl" type="primary" size="small" @click="copyUrl($event,child.pdfurl)">复制地址</van-button>
                <van-button v-if="child.hbbh" type="info" size="small" @click="handleViewHdmx(child)">合打明细</van-button>
                <van-button v-if="child.pdfurl" type="info" size="small" @click="handleViewPdf(child)">查看发票</van-button>
                <van-button v-if="child.pdfurl || child.dybz=='1'" type="danger" size="small" @click="changeZfsqVisible(child)">申请作废发票</van-button>
                <van-button v-else type="primary" size="small" @click="handleInvoiceOpen(child)">开票</van-button>
              </div>
            </div>
        </van-tab>
          </van-list>
      </van-tabs>

    </loading-layout>

    <van-action-sheet v-model="dialogVisible" title="确认开票" class="kp-box">
      <van-cell-group v-if="yhJbxx.nsbz == '0'" title="发票抬头">
        <van-cell title="统一结算号：" :value="yhJbxx.tyjsh && yhJbxx.tyjsh!=0?yhJbxx.tyjsh:'无'" :border="false" />
        <van-cell title="户号：" :value="yhJbxx.yhbh" :border="false" />
        <van-cell title="户名：" :value="yhJbxx.yhmc" :border="false" />
        <van-cell title="证件编号：" :value="yhJbxx.zjbh" :border="false" />
        <van-cell title="手机号：" :value="yhJbxx.lxsj" :border="false" />
      </van-cell-group>
      <van-cell-group v-else title="发票抬头">
        <van-cell title="统一结算号：" :value="yhJbxx.tyjsh && yhJbxx.tyjsh!=0?yhJbxx.tyjsh:'无'" :border="false" />
        <van-cell title="户号：" :value="yhJbxx.yhbh" :border="false" />
        <van-cell title="户名：" :value="yhJbxx.yhmc" :border="false" />
        <van-cell title="发票抬头：" :value="yhNsxx.nsrmc" :border="false" />
        <van-cell title="纳税标志：" :value="yhJbxx.nsbz | zfdmFilter(nsbzZfdmList)" :border="false" />
        <van-cell title="纳税人识别号：" :value="yhNsxx.nsrsbh" :border="false" />
        <van-cell title="地址、电话：" :value="yhNsxx.nsrdz + ' ' + yhNsxx.nsrdh" :border="false" />
        <van-cell title="开户行及账号：" :value="yhNsxx.nsrkhh+' ' +yhNsxx.nsryhzh" :border="false" />
      </van-cell-group>
      <van-cell-group title="开票信息" :border="false">
        <van-cell :border="false" title="账务月份：" :value="selectData.zwyf" />
        <van-cell :border="false" title="电费：" :value="selectData.ysdf | currency" />
        <van-cell :border="false" title="开票金额：" :value="selectData.wkdf | currency" />
        <van-cell :border="false" title="实收违约金：" :value="selectData.sswyj | currency" />
        <van-cell :border="false" title="开票包含违约金：">
          <template #right-icon>
            <van-switch v-model="iskjwyj" :disabled="yhJbxx.nsbz!=1?true:false"/>
          </template>
        </van-cell>
        <van-cell :border="false" title="备注说明(税务局要求):">
          <template #right-icon>
            <van-field v-model="bzsm" type="textarea" show-word-limit maxlength="120" name="bzsm" placeholder="请输入开票备注说明" style="width: 55%"/>
          </template>
        </van-cell>
      </van-cell-group>
      <div style="margin: 16px; margin-top: 32px;">
        <van-button block type="primary" native-type="submit" :disabled="invoiceOpening" @click="invoiceOpenDebounced">确认开票
        </van-button>
      </div>
    </van-action-sheet>
  </page-view>
</template>

<script>
  import {
    Tab,
    Tabs
  } from 'vant';
  import YhbhSelect from '@/components/YhbhSelect'
  import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
  import {
    yhJbxx_fetchOneYhJbxxByYhbh,
    yhNsxx_selectiveGetOne
  } from '@/api/zhcx';
  import {
    zwLjfysmx_getDzfpByYhbh,
    invoiceOpen
  } from '../../../api/invoice';
  import {
    zfdmManageService_getZfdmByDmzl
  } from '@/api/zfdm';
  import {
    zwLjfysmx_getDzfpByYhbhOrNf
  } from "@/api/psdmsdfzw/sfglschema/ZwLjfysmxAPI"
  import handleClipboard from '@/utils/clipboard'

  import {dzfpService_getDzfpZfsqFlag} from "@/api/psdmsdfzw/service/DzfpServiceAPI";
  import _ from 'lodash';
  export default {
    name: 'Dzfp',
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      YhbhSelect,
      YhbhEmpty
    },
    data() {
      return {
        title: '电子发票',
        yhbh: '',
        yhJbxx: {},
        yhNsxx: {},
        year: '',
        sort: '',
        list: [],
        loadedList: [],
        listLoading: false,
        listFinished: false,
        listLoadError: false,
        pageNum: 0,
        pageSize: 5,
        selectData: {},
        dialogVisible: false,
        invoiceOpening: false,
        nsbzZfdmList: [],
        activeTabIndex: 0,
        recordList:[],
        iskjwyj:true,//开票是否包含违约金标志
        bzsm:"",
      }
    },
    computed:{
      nf() {
        const currTab = this.list[this.activeTabIndex];
        return currTab && currTab.nf ? currTab.nf : null;
      }
    },
    methods: {
      fetchYhJbxx() {
        this.$toast.loading('加载中...')
        yhJbxx_fetchOneYhJbxxByYhbh(this.yhbh).then(res => {
          this.yhJbxx = res.content;
          this.iskjwyj = this.yhJbxx.nsbz == 1?false:true;
          if (this.yhJbxx.nsbz != '0') {
            this.fetchYhNsxx();
          }
          // 获取发票列表
          
          this.fetchDzfpList(true);
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          this.$toast.clear();
        });
      },
      fetchYhNsxx() {
        this.$toast.loading('加载中...')
        yhNsxx_selectiveGetOne({
          yhbh: this.yhbh
        }).then(res => {
          if (res.content) {
            this.yhNsxx = res.content;
          } else {
            throw new Error("无纳税信息!")
          }
          this.$toast.clear();
        }).catch(err => {
          console.log(err);
          this.$toast.clear();
        });
      },
      fetchDzfpList(isRefresh = false) {
        this.listFinished = false;
        this.listLoadError = false;
        this.recordList = [];
        this.listLoading = true;
        zwLjfysmx_getDzfpByYhbhOrNf([this.nf],this.yhbh).then(async res => {
          if (res.code == 200) {
            this.recordList = res.content;
            this.listFinished = true;
          } else {
            this.listLoadError = true;
          }
          this.listLoading = false;
        }).catch(err => {
          console.log(err);
          this.$toast(err.message);
          this.listLoading = false;
          this.listLoadError = true
        })
      },
      yhbhChangeHandle(jbxx) {
        this.yhbh = jbxx.yhbh;
        // 获取用户基本信息
        this.fetchYhJbxx();
      },
      // 开票
      handleInvoiceOpen(item) {
        if (this.yhJbxx.nsbz != '0' && !this.yhNsxx.nsrsbh ) {
           this.$dialog.alert({
            title: "提示",
            message: "纳税信息未维护，无法开票，请先到营业厅维护纳税信息。"
          });
        } else if (item.wkdf > 0) {
          this.selectData = item;
          this.bzsm = "";
          this.dialogVisible = true;
        } else {
          this.$dialog.alert({
            title: "提示",
            message: "未开票金额为0， 无法开具电子发票！"
          });
        }
      },
      // 开票确认
      invoiceOpenConfirm() {
        let wyjxx = this.iskjwyj?1:0;
        const {
          yhbh,
          zwyf
        } = this.selectData;
        const bzsm = this.bzsm;
        if (yhbh && zwyf) {
          this.invoiceOpening = true;
          let loading = this.$toast.loading({
            message: "正在开票",
            forbidClick: true,
            duration: 0
          })
          invoiceOpen({
            yhbh,
            zwyf,
            wyjxx,
            bzsm
          }).then(response => {
            let resp = (typeof response == 'string')?JSON.parse(response):response;
            if(resp.code == "9999") {
              this.dialogVisible = false;
              this.selectData = {};
              this.$toast.success("开票成功")
              this.fetchDzfpList(true); // 刷新列表
            } else {
              this.$dialog.alert({
                title: "开票失败",
                message: `${resp.message}(错误码：${resp.code})`,
                messageAlign: "left"
              });
            }
          }).catch(err => {
            this.$dialog.alert({
              title: "开票失败",
              message: err.message?err.message:err,
              messageAlign: "left"
            });
          }).finally(() => {
            this.invoiceOpening = false;
            loading.clear();
          })
        }
      },
      // 查看电子发票
      handleViewPdf(item) {
        if (item.pdfurl) {
          window.location.href = item.pdfurl;
          // let filename = `${item.yhbh}_${item.zwyf}.pdf`;
          // this.$router.push({
          //   path: '/pdf-viewer',
          //   query:{
          //     pdfurl: item.pdfurl + '?'+filename
          //   }
          // })
        } else {
          this.$toast("发票地址为空")
        }
      },
      onSubmit() {},
      onClick(name, title){
        this.activeTabIndex = name;
        this.fetchDzfpList()
      },
      copyUrl(event,url){
        handleClipboard(url,event);
      },
      editDzfptt(){
        if(this.yhJbxx.nsbz==0){
          this.$dialog.confirm({
            title: '提示',
            showCancelButton:false,
            message: '居民用户暂不支持在线修改,请到营业厅咨询办理!',
          })
          return;
        }
        this.$router.push({path: '/invoice-title-edit',query:{yhbh:this.yhJbxx.yhbh}});
      },
      handleViewHdmx(item){
        this.$router.push({
          path: '/hdjeRecord',
          query:{
            tyjsh: item.hbbh,
            zwyf: item.zwyf,
            jgbm: this.yhJbxx.jgbm,
          }
        });
      },
      changeKpsmVisible(){
        this.$dialog.confirm({
          title: '提示',
          messageAlign:'left',
          showCancelButton:false,
          message: '一、一般纳税人的电子发票（普通发票）可开低维费和违约金，电子发票（增值税专用发票）可开具电度电费、政府性基金及附加费（地方水利基金除外），地方水利建设基金电费不能开电子发票（需去营业厅开专用收据）。\n' +
              '二、非一般纳税人的电子发票（普通发票）中包含电费、低维费和违约金，地方水利建设基金电费不能开电子发票（需去营业厅开专用收据）。\n' +
              '三、如需维护发票抬头，请到当地营业厅咨询办理。',
        })
      },
      async changeZfsqVisible(item){
        let loading = this.$toast.loading({
          message: "页面跳转中...",
          forbidClick: true,
          duration: 0
        })
        let res = await dzfpService_getDzfpZfsqFlag(this.yhJbxx.jgbm,item.yhbh,item.zwyf,item.fphm)
        loading.clear();
        if(res.code==200){
          if(!res.content.data){
            await this.$dialog.alert({
              title: "提示",
              message: res.content.message
            });
          }else{
            await this.$router.push({
              path:"dzfpZfsq",
              query:{
                dybm:this.yhJbxx.dybm,
                yhbh:item.yhbh,
                yhmc:this.yhJbxx.yhmc,
                zwyf:item.zwyf,
                fphm:item.fphm,
              }
            });
          }
        }else{
          await this.$dialog.alert({
            title: "提示",
            message: res.content && res.content.message?res.content.message:res.message
          });
        }
      },
      invoiceOpenDebounced: _.debounce(function() {
        this.invoiceOpenConfirm();
      }, 1000),
    },
    created() {
      let tabs = [];
      let currYear = new Date().getFullYear();
      for (let i = 0; i < 5; i++) {
        tabs.push({
          name: currYear + '年',
          nf: currYear
        });
        currYear--;
      }
      this.list = tabs;
    },
    mounted() {
      this.$refs['loadingLayoutOuter'].setStatus('loading');
      this.$store.dispatch('getYhbhList').then(list => {
        this.yhbhList = list;
        if (list.length == 0) {
          this.$refs['loadingLayoutOuter'].setStatus('empty');
        } else {
          this.$refs['loadingLayoutOuter'].setStatus('success');
          this.yhbh = list[0].yhbh;
          this.yhbhChangeHandle(list[0]);
        }
      }).catch(err => {
        this.$refs['loadingLayoutOuter'].setStatus('error');
      });

      // 获取纳税标志字符代码
      zfdmManageService_getZfdmByDmzl('NSBZ').then(response => {
        this.nsbzZfdmList = response.content;
      });
    },
  }
</script>

<style lang="scss" scoped>
  $top-bg-color: #00b1ff;

  .dfzwPage /deep/ {
    .van-nav-bar {
      background-color: $top-bg-color;
      color: #fff;
    }

    .van-nav-bar .van-icon,
    .van-nav-bar__title {
      color: #fff;
    }

    .top {
      background-color: $top-bg-color;
      position: relative;
      padding: 16px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(/static/img/city-shadow.11e155ae.png);
        background-position: bottom;
        background-size: auto 90%;
        background-repeat: no-repeat;
        opacity: 0.13;
      }

      .van-cell-group,
      .van-cell {
        background-color: transparent;
      }

      .van-cell {
        color: #fff;
        padding: 0px;
        line-height: 28px;

        &::after {
          border: 0;
        }
      }

      .van-cell__title {
        flex: 0 0 auto;
      }

      .van-cell__value {
        color: #fff;
        text-align: left;
      }
    }
  }

  .dzfp-item /deep/ {
    background-color: #fff;
    margin: 10px;

    .dzfp-item::after{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: "";
      opacity: 0.1;
      background-image: url(/static/img/city-shadow.11e155ae.png);
      background-size: 80px 60px;
      background-position: -6px -10px;
      background-repeat: no-repeat;
      margin-left: 2px;
    }
    .dzfp-item--header {
      padding: 10px 16px;
    }

    .zwyf {
      background-color: #8c8c8c;
      font-size: 14px;
      color: #fff;
      margin-right: 5px;
    }

    .dzfp-item--content {
      padding: 10px 0;
      .van-cell {
        padding: 0 16px;
      }
    }

    .dzfp-item--actions {
      margin: 0 10px;
      padding: 10px 0;
      text-align: right;

      .van-button {
        margin-right: 5px;
        padding: 0 10px;
        border-radius: 3px;
        height: 28px;
        &:last-child {
          margin-right: 0;
        }
      }

      .divider {
        width: 1px;
        height: 16px;
        background-color: #ddd;
      }
    }
  }
  .invoice-detail-view{
    background-image: url(../../../assets/images/common/ele-invoice01.png);
    background-size: 60px 45px;
    background-repeat: no-repeat;
    background-position: top right;
  }
  .invoice-detail-view.is_hb {
    background-image: url(../../../assets/images/common/ele-invoice02.png);
  }
  .kp-box{
    .van-cell {
      padding: 0 16px !important;
    }
  }
</style>